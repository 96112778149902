<template>
  <section class="purchase-requisition">
    <div class="row">
      <div class="col">
        <h3>Заявка на приобретение инвестиционных паев</h3>
      </div>
      <div class="col-3">
        <p class="share-cost anketa">
          <span class="share-cost_label">Стоимость пая<rwm-question-mark footnote-number="2" text="Расчетная стоимость инвестиционного пая на последнюю дату определения стоимости чистых активов фонда" /></span
          >{{ shareCost.toLocaleString() }} р.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <rwm-checkbox
          label="Ознакомлен с "
          v-model="isDuAgree"
          link-text="правилами ДУ"
          :link-target="duTarget"
          @change="onchange"
          @downloadDU="$emit('downloadDU', $event)"
          :error="errors.isDuAgree"
        />
      </div>
      <div class="col-3">
        <rwm-text-field
          disabled
          width="100%"
          type="text"
          :value="timeOfDuAgree"
          label="Дата/Время ознакомления"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <rwm-text-field
          disabled
          width="100%"
          type="text"
          :value="fundAccountNumber"
          label="Номер лицевого счета"
        />
      </div>
    </div>
    <client-block
      :mname="user.userName.mname"
      :name="user.userName.name"
      :sname="user.userName.sname"
      :show-date="false"
    />
    <passport-block
      :department="user.passport.department"
      :issue="user.passport.issue"
      :issue-date="user.passport.issueDate"
      :number="user.passport.number"
    />
    <div class="row">
      <div class="col-9">
        <rwm-select
          placeholder="Банковские реквизиты"
          disabled
          :default-value="defaultAccount"
          :list="accountsList ? accountsList : []"
        />
      </div>
    </div>
    <div class="row">
      <p class="anketa" style="width: 100%">
        Прошу выдать инвестиционные паи фонда:
      </p>
      <div class="col-3 with-question">
        <rwm-text-field
          :disabled="fundStatus === 6 || (fundStatus === 3 && !havePurchases)"
          :value="shareQuantity"
          @input="shareQuantityValidation"
          label="Укажите количество шт."
          width="100%"
          :error="errors.shareQuantity"
          @blur="fundStatus === 6 || (fundStatus === 3 && !havePurchases) ? (onchange(), validate('shareQuantity&Sum')) : ''"
        />
        <rwm-question-mark footnote-number="1" :text="footnotes[0]" />
      </div>
      <div class="col-3 with-question">
<!--        <rwm-text-field-->
<!--          v-if="fundStatus === 3 && havePurchases"-->
<!--          disabled-->
<!--          label="Сумма"-->
<!--          width="100%"-->
<!--        />-->
        <rwm-text-field
          v-model="typedSumm"
          label="Сумма"
          width="100%"
          :error="errors.typedSumm"
          @blur="onchange()"
          @focus="typedSumm === 0 ? typedSumm = '' : ''"
        />
        <!-- <rwm-question-mark footnote-number="2" :text="footnotes[1]" /> -->
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <rwm-button width="100%" @click="submit" :disabled="isRedy"
          >Подписать</rwm-button
        >
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <rwm-sms-checking
          v-model="_timer"
          :isShow="showChecking"
          :time="60"
          @sendAgain="sendAgain()"
          @verified="codeIsVerified"
        />
      </div>
    </div>
    <rwm-footnote :footnotes="footnotes" />
  </section>
</template>

<script>
import ClientBlock from "../documents/forms/blocks/ClientBlock";
import PassportBlock from "../documents/forms/blocks/PassportBlock";
import RwmSmsChecking from "../../../RWMSMSChecking/RwmSmsChecking";
import RwmButton from "../../../RWMButton/RwmButton";
import RwmTextField from "../../../RWMTextField/RwmTextField";
import moment from "moment";
import RwmCheckbox from "../../../RWMCheckbox/RwmCheckbox";
import RwmSelect from "../../../RWMSelect/RwmSelect";
import RwmQuestionMark from "../../../RWMQutestionMark/RwmQuestionMark";
import RwmFootnote from "../../../RWMFootnote/RwmFootnote";
export default {
  name: "PurchaseRequisition",
  components: {
    RwmFootnote,
    RwmQuestionMark,
    RwmSelect,
    RwmCheckbox,
    RwmTextField,
    RwmButton,
    RwmSmsChecking,
    PassportBlock,
    ClientBlock,
  },
  props: {
    user: Object,
    timer: {
      type: Boolean,
      default: false,
    },
    showChecking: {
      type: Boolean,
      default: false,
    },
    fundAccountNumber: [Number, String],
    shareCost: [Number, String],
    accountsList: Array,
    bankAccount: [Number, String],
    fundId: [Number, String],
    fundStatus: [String, Number],
    havePurchases: Boolean,
    duTarget: [Object, null]
  },
  computed: {
    defaultAccount() {
      return this.accountsList.find((el) => el.id === this.bankAccount)?.name;
    },
    timeOfDuAgree() {
      return this.isDuAgree && this.timeOfDuAgreeDate
        ? moment(this.timeOfDuAgreeDate, "X").format("DD.MM.YYYY HH:mm:ss")
        : "";
    },
    _timer: {
      get() {
        return this.timer;
      },
      set(val) {
        this.$emit("timer", val);
      },
    },
  },
  data() {
    return {
      typedSumm: 0,
      isRedy: false,
      isDuAgree: false,
      shareQuantity: 0,
      timeOfDuAgreeDate: null,
      footnotes: [
        "Заполняется для заявки на приобретение инвестиционных паев, подаваемой в связи с осуществлением преимущественного права на приобретение дополнительных инвестиционных паев.",
        "Не заполняется для заявки на приобретение инвестиционных паев, подаваемой в связи с осуществлением преимущественного права на приобретение дополнительных инвестиционных паев.",
      ],
      errors: {
        isDuAgree: false,
        shareQuantity: false,
        typedSumm: false,
      },
    };
  },
  methods: {
    shareQuantityValidation(val) {
      this.isRedy = false;
      this.$emit('dataChanged')
      val = val.replace(",", ".").replace(/[^\d.?,?]/g, "");
      if(Math.round(parseFloat(val)*100000)/100000) {
        this.shareQuantity=Math.round(parseFloat(val)*100000)/100000
      } else {
        if (isNaN(parseFloat(val))) this.shareQuantity=0
      }
    },
    onchange() {
      this.isRedy = false;
      this.$emit('dataChanged')
      this.timeOfDuAgreeDate = new Date();
      this.typedSumm === '' ? this.typedSumm = 0 : ''
    },
    sendAgain() {
      this.$emit("sendAgain");
    },
    submit() {
      if (this.validate("all")) {
        this.$emit("submit");
      }
      this.isRedy = true;
    },
    validate(param) {
      if (param === "all") {
        if (this.validate("isDuAgree") && this.validate("shareQuantity&Sum")) {
          return true;
        } else return false;
      } else {
        if (param === "isDuAgree") {
          if (!this[param]) {
            this.errors[param] = !this[param];
            this.$toast.error("Необходимо ознакомиться с правилами");
          }
          return this[param];
        }
        if(param === "shareQuantity&Sum") {
          this.errors['typedSumm'] = false;
          this.errors['shareQuantity'] = false;
          if (this.fundStatus === 3 &&
                  this.havePurchases) {
            const quantity = this.shareQuantity.toLocaleString().replace(/[^\d]/g, "");
            let res = true;
            if (+quantity > 0) {
              res = this.validate('shareQuantity');
            }
            return res && this.validate('typedSumm');
          } else {
            return this.validate('typedSumm')
          }
        }
        if (param === "shareQuantity") {
          this.errors[param] = false;
          if (this.fundStatus === 3 &&
                  this.havePurchases) {
            this[param] = this[param].toLocaleString().replace(/[^\d]/g, "");
            if (this[param] != '') {
              if (!+this[param]) {
                this.$toast.error("Введите число");
                this.errors[param] = true;
                return false;

              }
            }
            return this[param] > 0;
          } else return true
        }
        if ( param === "typedSumm") {
          this[param] = this[param].toLocaleString().replace(/[^\d]/g, "");
          this.errors[param] = this[param] <= 0;
          if (this[param] <= 0) {
            this.$toast.error("Введите необходимую сумму для покупки паев");
            return false;
          }
          if (!+this[param]) {
            this.$toast.error("Введите число");
            return false;
          }
          this[param] = parseFloat(this[param]);
          this[param] = this[param].toLocaleString()
          return true;
        }
      }
    },
    codeIsVerified(code) {
      let sum = this.typedSumm.toString().length === 0 ? null : this.typedSumm
      let model = {
        bank_account_id: this.bankAccount, // ID банковских реквизитов
        requested_amount: this.shareQuantity, // Запрашиваемое количество
        requested_sum: sum, // Запрашиваемая сумма
        share_price: this.shareCost, // Цена пая (возвращается в модели после первого создания)
        fond_id: this.fundId,
        date_sign: moment(new Date()).format("X"),
        acquainted_rules_date: moment(this.timeOfDuAgreeDate).format("X"),
        signed: true,
      };
      this.$emit("verified", { code: code, form: model });
    },
  },
};
</script>

<style scoped lang="scss">
.purchase-requisition {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.share-cost {
  position: relative;
  color: $RWM-grey;
  @media screen and (min-width: 1280px) {
  }
  &_label {
    color: $RWM-green;
    margin-right: 20px;
    @media screen and (min-width: 1280px) {
      position: absolute;
      bottom: 100%;
      font-size: 16px;
      margin-right: 0;
    }
  }
}
.with-question {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
</style>
